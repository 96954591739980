.recipe-card {
    margin-bottom: 1rem;
}

.recipe-image-col {
    padding: 0;
}

.recipe-image-container {
    position: relative;
    width: 30%;
    padding-top: 30%; /* Creates a 1:1 aspect ratio */
}

.recipe-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}